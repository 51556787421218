.navBar {
    position: fixed;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 9999;
    /* transition: background-color 0.3s ease; */
    /* background:#000 ; */

}

.navBar2 {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 80%;
    z-index: 9999;
    left: 200px;
    background-color: #000000a9;
    border-radius: 40px;
    top: 80px;
    align-items: flex-end;

}

.navbar-scrolled {
    position: fixed;
    left: 0;
    border-radius: 0;
    top: 0;
    width: 100%;
    height: 80px;
    /* background:#B06440 linear-gradient(180deg, #B06440 90%,rgba(0 , 0, 0)100%) ; */
    display: flex;
    align-items: center;
    z-index: 9999;
    /* transition: background-color 0.3s ease; */
    background: #000;
    background: linear-gradient(180deg, rgb(0, 0, 0) 90%, #131313 100%);
}

.contain_navigate {
    position: relative;
    height: 65px;
    right: 50px;
}

.contain_navigate-scrolled {
    position: absolute;
    display: flex;
    right: 10px;
}

.contain_navigate ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-around;
    height: 100%;
    font-size: 15px;
    align-items: center;
    margin: 0;
}

.navigate {
    color: #c9c9c9;
    transition: color 0.5s ease;
    text-decoration: none;
    padding: 0 15px;
    height: 20px;
}

.navigate:hover {
    color: #B06440;
    border-radius: 10px;
    padding: 0 15px;
}

.contain_nb_image {
    /* display: flex; */
    position: relative;
    left: 40px;
    border-radius: 0px 0px 10px 10px;
}

.contain_navigate ul a {
    text-decoration: none;
    font-size: 18px;
}

.li_member {
    color: #c9c9c9;
    transition: color 0.3s ease;
    background-color: #b06340da;
    padding: 0 5px;
    border-radius: 5px
}

.li_member:hover {
    color: #000;
    background-color: #b06340;

}