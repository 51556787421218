.footerContain {
    position: absolute;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    background: #b06340;
}

.p_footer {
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #c9c9c9;
    transition: color 0.3s ease;
    cursor: pointer;
}

.div_footer {
    display: flex;
    align-items: center;
}

.ul_footer {
    margin-right: 10px;
    display: flex;
    list-style: none;
    color: #c9c9c9;
    cursor: pointer;
}

.ul_footer li {
    transition: color 0.3s ease;
}

.ul_footer li:hover {
    color: #000;
}

.p_footer:hover {
    color: #000;
}

.ul_footer li {
    margin: 5px;
}

@media (max-width: 650px) {
    .p_footer {
        font-size: small;
        margin: 0px;
    }

    .ul_footer {
        margin: 0px;
        display: flex;
        list-style: none;
        color: #c9c9c9;
        cursor: pointer;
        font-size: small;
    }
}