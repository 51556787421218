.event {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 10px;
    border-radius: 10px;
}

@media (max-width:600px) {
    .event {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: 10px;
        border-radius: 10px;
    }
}