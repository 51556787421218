.expo{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.containerr{
    margin-top: 200px;
    margin-bottom: 200px;
    text-align: center;
    color: #B06440;
}
.div {
    display: flex;
    margin-top: 50px;
    overflow: auto;
    padding-bottom: 100px;
    background-color: #B06440;
    border-radius: 10px;
    justify-content: space-between;
}
.exp{
    text-align: center;
    color: white;
}
.button-inscripciones{
    margin: 25px;
    padding: 20px;
    background-color: #B06440;
    color: white;
    border-radius: 5px;
}
.cont{
    /* scroll-snap-align: center; */
    justify-content: center;
    margin-top: 100px;
}
.iframe{
    justify-content: center;
    text-align: center;
    border: #B06440 solid 5px;
    width: 700px; 
    height: 600px;
}
