.sabermas {
    color: #000;
    background-color: #B06440;
    padding: 6px;
    border-radius: 5px;
    box-shadow: 3px 5px 5px rgba(51, 29, 3, 0.8);
    width: 300px;
    display: flex;
    text-align: center;
    transition: all .3s ease;
}

.sabermas:hover {
    transform: scale(1.01);
    color: aliceblue;
}

.imagen-zoom {
    flex-grow: 1;
    width: 66%;
}

.gradient {
    position: absolute;
    display: flex;
    top: 0;
    height: 100vh;
    width: 34%;
    z-index: 100;
    right: 1px;
    background: black;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;


}

.container-list-card {
    display: flex;
    justify-content: center;
    width: 100%;
}
.events{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: black;
    border-top: #B06440 solid 10px;
    
}
.ev{
    font-size: x-large;    
    color: white;
    padding: 25px;
    
}