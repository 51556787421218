.divHomeMobile {
    min-height: 100vh;
    width: 100%;
    background-color: black;
}

.contenedor-circular {
    width: 201px;
    height: 210px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

/* .contenedor-circular::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 60%;
    box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 1);
} */