.cont{
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    
}

@media (max-width: 768px) {
    .cont {
      flex-direction: column;
    }
}
  

.news{
    display: grid;
    width: max-content;
    height: 100vh;
    border: 5px solid black;
    margin: 20px;
    background-color: rgba(176, 100, 64, 0.8);
    /* background-color: rgb(176, 100, 64); */
    color: white;
}

